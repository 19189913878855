import Helmet from 'react-helmet'
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";

export default function SeoHead({
  site_name = "SRG",
  page_name = "Science, Engineering & Life Science Recruitment, Jobs & Staffing | SRG",
  description = "Market-leading  Science, Engineering & Life Sciences recruitment agency in the UK, US, and Europe, we  cultivate STEM careers for talented  STEM professionals",
  canonical_link = "",
  og_image = "https://www.srgtalent.com/site-assets/images/og-image.jpg",
  og_img_alt = "Science, Engineering & Life Science Recruitment, Jobs & Staffing | SRG",
  en_gb = true,
  en_us,
}) {
  const canonical_url = `https://www.srgtalent.com${en_us ? "/us" : ""}${
    canonical_link ? "/" + canonical_link : ""
  }`;
  const alternate_url = `https://www.srgtalent.com${en_gb ? "/us" : ""}${
    canonical_link ? "/" + canonical_link : ""
  }`;
  return (
    <>
      <SourceFlowHead
        metaObject={metaObject}
        siteName={site_name}
        title={page_name}
        description={description}
        locale={en_gb ? "en-GB" : en_us ? "en-US" : null}
        canonical={canonical_url}
        languageAlternates={[
          {
            hrefLang: en_gb ? "en-US" : en_us ? "en-GB" : null,
            href: alternate_url,
          },
        ]}
        images={[
          {
            url: og_image,
            width: 1200,
            height: 600,
            alt: og_img_alt,
            type: "image/jpg",
          },
        ]}
      />

      <Helmet>
        {en_gb && (
          <script type="text/javascript">
            {`
              window.hfAccountId = "98e638e5-3d52-4a88-8e86-cb63ec3e2c6a";
              window.hfDomain = "https://api.herefish.com";
              (function() {
                var hf = document.createElement('script'); hf.type = 'text/javascript'; hf.async = true;
                hf.src = window.hfDomain + '/scripts/hf.js';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(hf, s);
                })();
            `}
          </script>
        )}
        {en_us && (
          <script type="text/javascript">
            {`
              window.hfAccountId = "0a68c212-6bb7-4323-8277-f075952c6e87";
              window.hfDomain = "https://api.herefish.com";
              (function() {
                  var hf = document.createElement('script'); hf.type = 'text/javascript'; hf.async = true;
                  hf.src = window.hfDomain + '/scripts/hf.js';
                  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(hf, s);
              })();
            `}
          </script>
        )}
        {en_us && (
          /* WebSights - ZoomInfo */
          <script type="text/javascript">
            {`
              window[(function(_RYs,_kB){var _tS='';for(var _d4=0;_d4<_RYs.length;_d4++){var _Ch=_RYs[_d4].charCodeAt();_Ch!=_d4;_Ch-=_kB;_Ch+=61;_tS==_tS;_kB>4;_Ch%=94;_Ch+=33;_tS+=String.fromCharCode(_Ch)}return _tS})(atob('bl1kKCV+eXcqX3kv'), 20)] = '4363e801b11680116016';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_I0D,_Q9){var _So='';for(var _wg=0;_wg<_I0D.length;_wg++){var _Dq=_I0D[_wg].charCodeAt();_Dq-=_Q9;_Dq!=_wg;_Q9>4;_Dq+=61;_So==_So;_Dq%=94;_Dq+=33;_So+=String.fromCharCode(_Dq)}return _So})(atob('Kzc3MzZbUFAtNk89LE42JjUsMzc2TyYyMFA9LE43JCpPLTY='), 33)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });
            `}
          </script>
        )}
      </Helmet>

    </>
  );
}
