import { useState } from "react";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import JobSearch from "@sourceflow-uk/job-search";
import { Container, Row, Col } from "reactstrap";
import Link from "next/link";
import styles from "./styles.module.scss";
import Arrow from "/public/site-assets/svg/arrow.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion, useTransform, useScroll } from "framer-motion";
import BannerOverlay from "/public/site-assets/svg/banner-overlay.svg";
import imageMetaData from "../../.sourceflow/image_metadata.json";
import SimpleForm from "/components/SimpleForm";
import Helmet from 'react-helmet'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default function MainBanner({
  global,
  pathPrefix,
  customClass = "",
  content,
  imageURL1,
  imageURL2,
  imageALT1,
  buttonTitle,
  buttonLink,
  popUpModal_1 = false,
  jobSearch = false,
  displayImage1 = true,
  pageAnchor = false,
  editableContent = true,
  h1_content,
  locale = "https://www.srgtalent.com",
  bots,
  popUpModal_1_form_id,
  dialog_content_1,
  successPageUrl
}) {

  Fancybox.bind("[data-src]", {
    // Your custom options
});

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const variants_1 = {
    hidden: { opacity: 0.005, y: 40 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.05 } },
  };
  const variants_2 = {
    hidden: { opacity: 0.005, y: 40 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.2 } },
  };
  return (
    <section
      className={`${styles.root} ${customClass} position-relative ${displayImage1 ? null : "hidden-image-1"
        }`}
    >
      <Helmet>
        {imageURL1 && <link rel="prefetch" href={imageURL1} />}
      </Helmet>
      <Container>
        <Row className="m-0">
          <Col
            lg={6}
            className="contentWrapper d-flex flex-wrap align-items-center position-relative"
          >
            <div>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={variants_1}
              >
                {editableContent ? (
                  <>
                    <h1>
                      <SourceFlowText
                        global={global}
                        path={`${pathPrefix}.h1_content`}
                      >
                        {h1_content}
                      </SourceFlowText>
                    </h1>
                    <SourceFlowText
                      global={global}
                      path={`${pathPrefix}.content`}
                      type="html"
                    >
                      {content}
                    </SourceFlowText>

                    {popUpModal_1 ? (
                      <>
                        <button
                          className="border-0 fancyBox primaryBtn no-arrow"
                          data-src={`#dialog-content-1`}
                          onClick={handleButtonClick}
                        >
                          {buttonTitle}
                        </button>
                      </>
                    ) : buttonLink ? (
                      <div>
                        <Link href={`${buttonLink}`}>
                          <a className="primaryBtn no-arrow">{buttonTitle}</a>
                        </Link>
                      </div>
                    ) : null}
                    {isModalVisible && popUpModal_1_form_id ? (
                      <div className="dialog-content fancyBox" id="dialog-content-1">
                        <div>
                          {dialog_content_1 ? (
                            <>
                              <SourceFlowText
                                global={global}
                                path={`${pathPrefix}.dialog-content-1`}
                                type="html"
                              >
                                {dialog_content_1}
                              </SourceFlowText>
                              <hr />
                              <SimpleForm
                                formId={`${popUpModal_1_form_id}`}
                                successPageUrl={`${successPageUrl}`}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {bots && <div>{bots}</div>}

                  </>
                ) : (
                  content
                )}
              </motion.div>
              {jobSearch ? (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={variants_2}
                >
                  <JobSearch
                    options={{
                      searchResults: false,
                      searchFilters: false,
                      submitDestination: `${locale ? locale : ""}/jobs`,
                    }}
                    translations={{
                      "input.placeHolder": "Job Title or Keyword",
                    }}
                  />
                </motion.div>
              ) : null}

            </div>
          </Col>
          {displayImage1 ? (
            <Col lg={6} className="position-relative mainImageWrapper">
              <SourceFlowImage
                src={imageURL1}
                size="1000x"
                alt={imageALT1}
                className="card-img rounded-4"
                imagesMetaData={imageMetaData}
                path={pathPrefix ? pathPrefix : `${pathPrefix}.image`}
                loading={"eager"}
              />
            </Col>
          ) : null}
          <div className="imageWrapper position-absolute p-0">
            {/* <SourceFlowImage
                            src={imageURL2}
                            size="1000x"
                            alt={imageALT2}
                            className="card-img rounded-0"
                        /> */}
            <BannerOverlay />
          </div>
        </Row>
        {pageAnchor ? (
          <AnchorLink
            offset="80"
            href={`#${pageAnchor}`}
            className="banner-anchor position-absolute"
            aria-label="Scroll Bottom"
          >
            <Arrow />
          </AnchorLink>
        ) : null}
        {popUpModal_1 ? (
          <button
            className="border-0 fancyBox primaryBtn no-arrow"
            data-src={`#dialog-content-1`}
          >
            {buttonTitle}
          </button>
        ) : buttonLink ? (
          <div>
            <Link href={`${buttonLink}`}>
              <a className="tertiaryBtn no-arrow m-0">{buttonTitle}</a>
            </Link>
          </div>
        ) : null}
      </Container>
    </section>
  );
}
